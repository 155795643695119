import React from 'react';
import { Box, makeStyles, TableRow, TableCell } from '@material-ui/core';
import BodyText from './BodyText';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toggleFlightDetailModal } from '../../reducers/UiReducer';
const useStyles = makeStyles(() => ({
	optionsIcon: {
		height: 25,
		width: 25,
		backgroundColor: '#FDE8EE',
		padding: 3,
		borderRadius: '50%',
	},
	tableCell: {
		fontSize: 14,
		// padding: 3,
		// color: 'rgb(0 0 0 / 73%)',
		fontWeight: 600,
	},
	img: {
		'& .MuiAvatar-img': {
			width: 30,
			height: 30,
		},
	},
}));
const FlightSegment = ({ parseData }) => {
	const classes = useStyles();
	console.log(
		'🚀 ~ file: FlightSegment.js ~ line 30 ~ FlightSegment ~ par̥seData',
		parseData
	);

	return (
		<>
			{parseData?.Supplier === 'ETR'
				? parseData?.Segments?.map((item, index) => {
						return (
							<TableRow style={{ marginTop: 20 }}>
								<TableCell className={classes.tableCell}>
									{item?.Airline_Name}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Airline_Code}-{item?.Aircraft_Type}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Origin_City},{item?.Origin}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Destination_City},{item?.Destination}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Departure_DateTime}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Arrival_DateTime}
								</TableCell>
							</TableRow>
						);
				  })
				: parseData?.Supplier === 'TBO'
				? parseData?.Segments[0]?.map((item, index) => {
						return (
							<TableRow style={{ marginTop: 20 }}>
								<TableCell className={classes.tableCell}>
									{item?.Airline.AirlineName}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Airline.AirlineCode}-{item?.Airline.FlightNumber}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Origin.Airport.CityName},
									{item?.Origin.Airport.CityCode}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Destination.Airport.AirportName},
									{item?.Destination.Airport.AirportCode}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Origin.DepTime}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{item?.Destination.ArrTime}
								</TableCell>
							</TableRow>
						);
				  })
				: ''}
		</>
	);
};

export default FlightSegment;
