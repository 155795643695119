import React from 'react';
import { Box, makeStyles, TableRow, TableCell } from '@material-ui/core';
import BodyText from './BodyText';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toggleFlightDetailModal } from '../../reducers/UiReducer';
const useStyles = makeStyles(() => ({
	optionsIcon: {
		height: 25,
		width: 25,
		backgroundColor: '#FDE8EE',
		padding: 3,
		borderRadius: '50%',
	},
	tableCell: {
		fontSize: 14,
		// padding: 3,
		// color: 'rgb(0 0 0 / 73%)',
		fontWeight: 600,
	},
	img: {
		'& .MuiAvatar-img': {
			width: 30,
			height: 30,
		},
	},
}));
const PassengerDetailRow = ({ parseData }) => {
	const classes = useStyles();
	console.log('first', parseData);
	return (
		<>
			<TableRow style={{ marginTop: 20 }}>
				<TableCell className={classes.tableCell}>
					{parseData[0].PaxType === 1
						? 'Adult'
						: parseData[0].PaxType === 2
						? 'Child'
						: parseData[0].PaxType === 3
						? 'Infant'
						: ''}
				</TableCell>
				<TableCell
					className={
						classes.tableCell
					}>{`${parseData[0].title} ${parseData[0].firstname} ${parseData[0].lastname}`}</TableCell>
				<TableCell className={classes.tableCell}>-</TableCell>
				<TableCell className={classes.tableCell}></TableCell>
				<TableCell className={classes.tableCell}></TableCell>
			</TableRow>
		</>
	);
};

export default PassengerDetailRow;
