import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
		// marginTop:60,
		[theme.breakpoints.down('xs')]: {
			marginTop: 0,
		},
	},
}));

export default function Linear() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<LinearProgress style={{ height: 10 }} />
			{/* <LinearProgress color="secondary" /> */}
		</div>
	);
}
