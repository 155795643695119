import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Slider,
	withStyles,
	// FormControlLabel,
	Checkbox,
	Hidden,
	useMediaQuery,
	useTheme,
	Divider,
} from '@material-ui/core';
// import Razorpay from '../../assets/images/razorpay.png';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import {
	busBlockDispatch,
	addBusBookingDispatch,
} from '../../reducers/BusReducer';
import { walletBalanceAgentDispatch } from '../../reducers/UserReducer';
import { toggleEasebuzzModal } from '../../reducers/UiReducer';
import EassBuzzBusModal from '../../components/reusableComponents/EassBuzzBusModal';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import Footer from './Footer';
import BusconfirmModal from '../../components/reusableComponents/BusconfirmModal';
import SessionExpiredModal from '../../components/reusableComponents/SessionExpiredModal';
import LoaderModal from '../../components/reusableComponents/LoaderModal';

const PaymentDetailsBus = () => {
	const history = useHistory();
	const location = useLocation();
	const [pay, setPay] = useState('');
	const date = new Date();
	const dispatch = useDispatch();
	console.log('location', location);
	const { loadingbus2, loadingbus } = useSelector((state) => state.bus);
	const { walletagent, agentlogin } = useSelector((state) => state.user);
	const handlepay = (value) => {
		setPay(value);
	};
	const getActualFormat = (given: number) => {
		if (given < 10) {
			return 0 + '' + given.toString();
		} else {
			return given;
		}
	};
	function timeConvertbus(a) {
		let arhour = Math.floor(a / 60);
		if (arhour > 24) {
			arhour = arhour - 24;
		}
		let armin = a % 60;
		let format = `${arhour}:${armin === 0 ? '00' : armin}:00`;
		function formatTime(timeString) {
			const [hourString, minute] = timeString.split(':');
			const hour = +hourString % 24;
			return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM');
		}
		return formatTime(format);
		console.log('first', armin);
	}
	const invoice = [
		date.getFullYear(),
		getActualFormat(date.getMonth() + 1),
		getActualFormat(date.getDate()),
		getActualFormat(date.getHours()),
		getActualFormat(date.getMinutes()),
		getActualFormat(date.getSeconds()),
	].join('');
	useEffect(() => {
		if (agentlogin && agentlogin.status === 200) {
			// let logodata = {
			// 	agent_id: agentlogin && agentlogin.result.result.id,
			// };
			// dispatch(agentlogogetDispatch(logodata));

			dispatch(
				walletBalanceAgentDispatch(
					agentlogin && agentlogin.result.result.email,
					agentlogin && agentlogin.result.result.id
				)
			);
		}
	}, []);
	let combine = [];
	if (location.state) {
		location.state.data.Passengers.map((item, index) => {
			location.state.selectSeat.map((i, ind) => {
				if (index === ind) {
					combine.push({
						...i,
						passengerName: item,
					});
				}
			});
		});
	}
	console.log('Combine', combine);
	let PGCharge =
		Number(
			location.state.selectSeat &&
				location.state?.selectSeat
					?.map((item) => parseInt(item.totalFare))
					.reduce((prev, next) => prev + next)
		) +
		Number(
			Math.round(
				Number(
					Number(
						location.state.selectSeat &&
							location.state?.selectSeat
								?.map((item) => parseInt(item.totalFare))
								.reduce((prev, next) => prev + next)
					) * 2.5
				) / 100
			)
		);
	const handlePayment = () => {
		let formData = {
			orderId: location.state.orderId,
			paymentId: invoice,
			availableTripId: location.state.selectedTripID,
			boardingPointId: location.state.boardingPointId,
			destination: location.state.des.id,
			inventoryItems: combine.map((item, index) => {
				return {
					fare: item.fare,
					ladiesSeat: item.ladiesSeat,
					passenger: {
						address: '',
						age: item.passengerName.age,
						email: location.state.data.email,
						gender: item.passengerName.gender,
						idNumber: '',
						idType: '',
						mobile: location.state.data.phone,
						name: item.passengerName.name,
						primary: 'true',
						title: '',
					},
					seatName: item.name,
				};
			}),
			source: location.state.source.id,
		};
		let addBusBooking = {
			orderId: location.state.orderId,
			supplier: location.state.item.Supplier,
			paymentStatus: '0',
			userEmail: location.state.data.email,
			userPhone: location.state.data.phone,
			user_id: 'T-1',
			user_type: 'Guest',
			departureCity: location?.state?.source?.name,
			arrivalCity: location?.state?.des?.name,
			doj: location.state.item.doj,
			dor: '',
			journeyType: 'OneWay',
			nop: location.state.selectSeat.length,
			PGCharge: pay === 'offline' ? PGCharge : '',
			actualFare:
				location.state.selectSeat &&
				location.state?.selectSeat
					?.map((item) => parseInt(item.actualFare))
					.reduce((prev, next) => prev + next),
			markup:
				location.state.selectSeat &&
				location.state?.selectSeat
					?.map((item) => parseInt(item.markup))
					.reduce((prev, next) => prev + next),
			baseFare:
				location.state.selectSeat &&
				location.state?.selectSeat
					?.map((item) => parseInt(item.baseFare))
					.reduce((prev, next) => prev + next),
			partnerMarkup:
				location.state.selectSeat &&
				location.state?.selectSeat
					?.map((item) => parseInt(item.partnerMarkup))
					.reduce((prev, next) => prev + next),
			agentMarkup:
				location.state.selectSeat &&
				location.state?.selectSeat
					?.map((item) => parseInt(item.agentMarkup))
					.reduce((prev, next) => prev + next),
			totalFare:
				location.state.selectSeat &&
				location.state?.selectSeat
					?.map((item) => parseInt(item.totalFare))
					.reduce((prev, next) => prev + next),
			payableAmount:
				pay === 'offline'
					? Number(
							location.state.selectSeat &&
								location.state?.selectSeat
									?.map((item) => parseInt(item.totalFare))
									.reduce((prev, next) => prev + next)
					  )
					: Number(
							location.state.selectSeat &&
								location.state?.selectSeat
									?.map((item) => parseInt(item.totalFare))
									.reduce((prev, next) => prev + next)
					  ) + Number(PGCharge),
			ticketCost:
				pay === 'offline'
					? Number(
							location.state.selectSeat &&
								location.state?.selectSeat
									?.map((item) => parseInt(item.totalFare))
									.reduce((prev, next) => prev + next)
					  )
					: Number(
							location.state.selectSeat &&
								location.state?.selectSeat
									?.map((item) => parseInt(item.totalFare))
									.reduce((prev, next) => prev + next)
					  ) + Number(PGCharge),
			totalEarn: '0',
			cashBack: '0.00',
			paymentMode: pay === 'easebuzz' ? 'ONLINE' : 'OFFLINE',
			couponDiscount: 0.0,
			couponDetails: '',
			selectedPax: location.state.data.Passengers,
			ticketSent: 0,
			agent_id: 1,
		};
		// console.log('Formdata', addBusBooking);
		dispatch(addBusBookingDispatch(addBusBooking, formData, pay));

		console.log('Bus', formData);
		// dispatch(busBlockDispatch(formData));
		// history.push('/busticket');
		// console.log('online');
	};
	return (
		<>
			<HeaderSection />
			<Box style={{ background: 'rgb(238, 244, 253)' }}>
				{/* <h4 style={{ color: '#3e3e52', fontFamily: 'Montserrat',marginTop:100 }}>
					Bus Review Details
				</h4> */}
				<Container>
					<Grid container spacing={2} style={{ marginTop: 50 }}>
						<Grid item xs={8} style={{ marginTop: 60 }}>
							<Box
								style={{
									boxShadow: '0 3px 11px 0 rgb(0 0 0 / 12%)',
									padding: 20,
									border: '1px solid lightblue',
									background: 'white',
									borderRadius: 10,
								}}>
								<Box style={{ display: 'flex' }}>
									<h5>{location?.state?.source?.name}</h5>
									<h5>
										<i
											class='fa-solid fa-arrow-right'
											style={{ marginLeft: 15 }}></i>
									</h5>
									<h5 style={{ marginLeft: 15 }}>
										{location?.state?.des?.name}
									</h5>
									<h5 style={{ marginLeft: 15 }}>
										{location?.state?.item?.doj.split('T')[0]}
									</h5>
								</Box>
								<Box
									display='flex'
									justifyContent='space-between'
									alignItems='center'>
									<Box>
										<span style={{ fontSize: 18 }}>
											{location?.state?.item?.travels}
										</span>
										<p style={{ fontSize: 16 }}>
											{location?.state?.item?.busType}
										</p>
									</Box>
									<Box>
										<span style={{ fontSize: 18, fontWeight: 'bold' }}>
											{timeConvertbus(location?.state.item.departureTime)}
										</span>
										<br></br>
										<span style={{ fontSize: 18 }}>
											{location.state.source.name}
										</span>
									</Box>
									<Box>
										<span style={{ fontSize: 18, fontWeight: 'bold' }}>
											{timeConvertbus(location?.state.item.arrivalTime)}
										</span>
										<br></br>
										<span style={{ fontSize: 18 }}>
											{location.state.des.name}
										</span>
									</Box>
								</Box>
							</Box>
							<h4
								style={{
									color: '#3e3e52',
									fontFamily: 'Montserrat',
									marginTop: 10,
								}}>
								Contact Details
							</h4>
							<Box
								style={{
									boxShadow: '0 3px 11px 0 rgb(0 0 0 / 12%)',
									marginTop: 20,
									padding: 20,
									border: '1px solid lightblue',
									background: 'white',
									borderRadius: 10,
								}}>
								<Box
									display='flex'
									alignItems='center'
									justifyContent='space-around'>
									<Box>
										<span style={{ fontSize: 20 }}>
											Phone: {location.state.data.phone}
										</span>
									</Box>
									<Box>
										<span style={{ fontSize: 20 }}>
											Email: {location.state.data.email}
										</span>
									</Box>
								</Box>
							</Box>
							<h4
								style={{
									color: '#3e3e52',
									fontFamily: 'Montserrat',
									marginTop: 10,
								}}>
								Passenger Details
							</h4>
							<Box
								style={{
									boxShadow: '0 3px 11px 0 rgb(0 0 0 / 12%)',
									marginTop: 20,
									padding: 20,
									border: '1px solid lightblue',
									background: 'white',
									borderRadius: 10,
								}}>
								<Box>
									{location.state.data.Passengers.map((i, index) => {
										return (
											<>
												<h5>{`${index + 1}.${i.name}, ${i.age}, ${
													i.gender
												}`}</h5>
											</>
										);
									})}
								</Box>
							</Box>
							<h4
								style={{
									color: '#3e3e52',
									fontFamily: 'Montserrat',
									marginTop: 10,
								}}>
								Choose Payment Method
							</h4>
							<Box
								style={{
									boxShadow: '0 3px 11px 0 rgb(0 0 0 / 12%)',
									marginTop: 20,
									border: '1px solid lightblue',
									background: 'white',
									borderRadius: 10,
								}}>
								<Box
									onClick={() => handlepay('easebuzz')}
									style={{
										borderRadius: '2px',
										cursor: 'pointer',
										backgroundColor:
											pay === 'easebuzz'
												? 'rgb(219 235 255 / 69%)'
												: 'rgb(255, 255, 255)',
										padding: '1.3em',
										display: 'flex',
										// flex-direction: column;
										// -webkit-box-pack: center;
										// justifyContent: 'center',
										borderBottom: '1px solid rgb(221, 221, 221)',
									}}>
									{/* <img src={Razorpay} style={{width:20,height:20}}></img> */}
									<span style={{ fontFamily: 'Montserrat', fontSize: 20 }}>
										Pay through Easebuzz{' '}
										<span style={{ color: 'green', marginLeft: 10 }}>
											₹{' '}
											{Number(
												location.state.selectSeat &&
													location.state?.selectSeat
														?.map((item) => parseInt(item.totalFare))
														.reduce((prev, next) => prev + next)
											) +
												Number(
													Math.round(
														Number(
															Number(
																location.state.selectSeat &&
																	location.state?.selectSeat
																		?.map((item) => parseInt(item.totalFare))
																		.reduce((prev, next) => prev + next)
															) * 2.5
														) / 100
													)
												)}
										</span>
									</span>
								</Box>
								{agentlogin && agentlogin.status == 200 ? (
									<Box
										onClick={() =>
											Number(
												location.state.selectSeat &&
													location.state?.selectSeat
														?.map((item) => parseInt(item.totalFare))
														.reduce((prev, next) => prev + next)
											) <= Number(walletagent?.result?.balanceAmount)
												? handlepay('offline')
												: ''
										}
										style={{
											borderRadius: '2px',
											cursor: 'pointer',
											backgroundColor:
												Number(
													location.state.selectSeat &&
														location.state?.selectSeat
															?.map((item) => parseInt(item.totalFare))
															.reduce((prev, next) => prev + next)
												) <= Number(walletagent?.result?.balanceAmount)
													? pay === 'offline'
														? 'rgb(219 235 255 / 69%)'
														: 'rgb(255, 255, 255)'
													: 'white',
											padding: '1.3em',
											display: 'flex',
											alignItems: 'center',
											// flex-direction: column;
											// -webkit-box-pack: center;
											// justifyContent: 'space-between',
											borderBottom: '1px solid rgb(221, 221, 221)',
										}}>
										<span
											style={{
												fontFamily: 'Montserrat',
												fontSize: 20,
												color:
													Number(
														location.state.selectSeat &&
															location.state?.selectSeat
																?.map((item) => parseInt(item.totalFare))
																.reduce((prev, next) => prev + next)
													) <= Number(walletagent?.result?.balanceAmount)
														? ''
														: 'lightgrey',
											}}>
											Pay through Offline
											<span
												style={{
													color:
														Number(
															location.state.selectSeat &&
																location.state?.selectSeat
																	?.map((item) => parseInt(item.totalFare))
																	.reduce((prev, next) => prev + next)
														) <= Number(walletagent?.result?.balanceAmount)
															? 'green'
															: 'lightgrey',
													marginLeft: 10,
												}}>
												₹{' '}
												{location.state.selectSeat &&
													location.state?.selectSeat
														?.map((item) => parseInt(item.totalFare))
														.reduce((prev, next) => prev + next)}
											</span>
										</span>
										{Number(
											location.state.selectSeat &&
												location.state?.selectSeat
													?.map((item) => parseInt(item.totalFare))
													.reduce((prev, next) => prev + next)
										) <= Number(walletagent?.result?.balanceAmount) ? (
											''
										) : (
											<p style={{ color: 'red', marginLeft: 10 }}>
												Insufficent Balance
											</p>
										)}
									</Box>
								) : (
									''
								)}
							</Box>
						</Grid>
						<Grid item xs={12} lg={4} style={{ marginTop: 60 }}>
							<Box style={{ position: 'sticky', top: 84 }}>
								<Box
									style={{
										background: 'white',
										padding: '10px 15px',
										border: '1px solid #e6e6e6',
										// marginTop: '62px',
										boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
										borderRadius: '5px',
										// position: 'sticky',
										// top: 84,
										zIndex: 1,
									}}>
									<Grid container alignItems='center'>
										<Grid item xs={4}>
											Fare Details
										</Grid>
										<Grid item xs={8}>
											<Divider style={{ background: 'black' }} />
										</Grid>
									</Grid>
									<Box
										style={{
											background: 'rgb(166 185 255 / 17%)',
											padding: '5px 10px',
											margin: '10px 0 0',
											borderRadius: '15px',
										}}>
										<BodyText textStyle={{ fontSize: 13 }}>
											AMOUNT TO BE PAID
										</BodyText>
										<TitleText textStyle={{ fontSize: 18 }}>
											{`₹ ${
												Number(
													location.state.selectSeat &&
														location.state?.selectSeat
															?.map((item) => parseInt(item.totalFare))
															.reduce((prev, next) => prev + next)
												) +
												Number(
													pay === 'easebuzz'
														? Math.round(
																Number(
																	Number(
																		location.state.selectSeat &&
																			location.state?.selectSeat
																				?.map((item) =>
																					parseInt(item.totalFare)
																				)
																				.reduce((prev, next) => prev + next)
																	) * 2.5
																) / 100
														  )
														: 0
												)
											}`}
										</TitleText>
										<span>(inclusive of all taxes.)</span>
									</Box>
									<Box
										display='flex'
										justifyContent='space-between'
										mt={'10px'}>
										<BodyText textStyle={{ fontSize: 13 }}>
											Adultx
											{location.state.selectSeat.length}
										</BodyText>
										<TitleText textStyle={{ fontSize: 13 }}>
											{`₹ ${
												location.state.selectSeat &&
												location.state?.selectSeat
													?.map((item) => parseInt(item.supplierFare))
													.reduce((prev, next) => prev + next)
											}`}
										</TitleText>
									</Box>

									<Box display='flex' justifyContent='space-between'>
										<BodyText textStyle={{ fontSize: 13 }}>
											Total Taxes
										</BodyText>
										<TitleText textStyle={{ fontSize: 13 }}>
											₹{' '}
											{location.state.selectSeat &&
												location.state?.selectSeat
													?.map((item) => parseInt(item.serviceTaxAbsolute))
													.reduce((prev, next) => prev + next)}
										</TitleText>
									</Box>
									<Divider />
									{pay === 'easebuzz' ? (
										<Box
											display='flex'
											justifyContent='space-between'
											mt={'5px'}
											mb={'5px'}>
											<BodyText>Convenience Fees</BodyText>
											<TitleText two>
												₹{' '}
												{Number(
													Math.round(
														Number(
															Number(
																location.state.selectSeat &&
																	location.state?.selectSeat
																		?.map((item) => parseInt(item.totalFare))
																		.reduce((prev, next) => prev + next)
															) * 2.5
														) / 100
													)
												)}
											</TitleText>
										</Box>
									) : (
										''
									)}

									<Divider />
								</Box>
							</Box>
						</Grid>
					</Grid>
					<Grid container style={{ marginTop: 50 }}>
						<Grid item xs={4}>
							{pay ? (
								<Box
									// onClick={() => {
									// 	pay === 'easebuzz' ? dispatch(toggleEasebuzzModal(true)) : '';
									// }}
									onClick={handlePayment}
									style={{
										backgroundColor: 'rgb(239, 51, 57)',
										color: 'white',
										borderRadius: 5,
										textAlign: 'center',
										cursor: 'pointer',
										width: '100%',
										padding: '11px 8px',
										'&:hover': {
											backgroundColor: 'rgb(239, 51, 57)',
										},
									}}>
									<span>Procced to Payment</span>
								</Box>
							) : (
								''
							)}
						</Grid>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}></Grid>
					</Grid>
					<EassBuzzBusModal pay={pay} />
					<BusconfirmModal />
					<SessionExpiredModal />
					{loadingbus || loadingbus2 ? <LoaderModal /> : ''}
				</Container>
			</Box>
			<Footer />
		</>
	);
};

export default PaymentDetailsBus;
