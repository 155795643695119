import React from 'react';
import {
	Container,
	Box,
	Grid,
	Paper,
	Divider,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	Avatar,
	withStyles,
	TableCell,
} from '@material-ui/core';
import SMTQR from '../../assets/images/SMTQR.jpeg';
import TitleText from './TitleText';
import BodyText from './BodyText';
const BankDetails = () => {
	return (
		<>
			<Grid container spacing={4} style={{ paddingLeft: 50, paddingRight: 50 }}>
				<Grid item xs={12} md={6} lg={4}>
					<TitleText
						four
						textStyle={{
							fontFamily: 'Josefin Sans, sans-serif',
							fontWeight: 500,
							fontSize: 18,
						}}>
						HDFC Bank Detail
					</TitleText>
					<Box
						mt={'20px'}
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<TitleText three textStyle={{ fontSize: 16 }}>
							Bank Name:
						</TitleText>
						<BodyText three textStyle={{ fontSize: 16 }}>
							HDFC Bank
						</BodyText>
					</Box>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<TitleText three textStyle={{ fontSize: 16 }}>
							A/c No:
						</TitleText>
						<BodyText three textStyle={{ fontSize: 16 }}>
							50200061282501
						</BodyText>
					</Box>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<TitleText three textStyle={{ fontSize: 16 }}>
							IFSC code:
						</TitleText>
						<BodyText three textStyle={{ fontSize: 16 }}>
							HDFC0004043
						</BodyText>
					</Box>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<TitleText three textStyle={{ fontSize: 16 }}>
							AC/ Holder:
						</TitleText>
						<BodyText three textStyle={{ fontSize: 16 }}>
							SELL MY TRIP
						</BodyText>
					</Box>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<TitleText three textStyle={{ fontSize: 16 }}>
							Branch :
						</TitleText>
						<BodyText three textStyle={{ fontSize: 16 }}>
							RAMSNEHI GHAT
						</BodyText>
					</Box>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<TitleText three textStyle={{ fontSize: 16 }}>
							Account Type :
						</TitleText>
						<BodyText three textStyle={{ fontSize: 16 }}>
							CURRENT
						</BodyText>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} lg={2}></Grid>
				<Grid item xs={12} md={6} lg={4}>
					<TitleText
						four
						textStyle={{
							fontFamily: 'Josefin Sans, sans-serif',
							fontWeight: 500,
							fontSize: 18,
						}}>
						UPI
					</TitleText>
					<img src={SMTQR} style={{ width: '100%' }} />
				</Grid>
			</Grid>
		</>
	);
};

export default BankDetails;
