import React from 'react';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import ErrorModalIcon from '../../assets/images/ErrorModalIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { toggleErrorModal, setErrorMessage } from '../../reducers/UiReducer';
import BodyText from './BodyText';
import TitleText from './TitleText';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		backdropFilter: 'blur(2px)',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(2.5),
		},
		color: theme.palette.grey[500],
	},
}));

const ErrorModal = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { errorModal, errorMessage } = useSelector((state) => state.ui);
	const handleClose = () => {
		dispatch(toggleErrorModal(false));
		dispatch(setErrorMessage(''));
	};
	return (
		<Dialog
			PaperProps={{
				style: {
					borderRadius: 20,
				},
			}}
			TransitionProps={{
				style: { backgroundColor: 'transparent' },
			}}
			onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			className={classes.root}
			open={errorModal}
			fullWidth={true}
			maxWidth={'xs'}>
			<DialogContent
				style={{
					paddingBottom: 20,
					paddingTop: 50,
				}}>
				<TitleText textStyle={{ fontWeight: 700, textAlign: 'center' }}>
					Oops!
				</TitleText>
				<BodyText
					textStyle={{ fontWeight: 500, textAlign: 'center', marginTop: 20 }}>
					{errorMessage}
				</BodyText>
			</DialogContent>
			<img
				src={ErrorModalIcon}
				alt='error-modal-icon'
				style={{
					position: 'fixed',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			/>
		</Dialog>
	);
};

export default ErrorModal;
